<template>
  <div class="pa-5">
    <span class="title">🌈 준비중입니다. </span>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
